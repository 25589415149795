import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Yksi from "../images/profile.jpg"
import Footer from "../components/footer"
import FbImg from "../images/f_logo_RGB-Black_58.png"
import IgImg from "../images/instagram-loco-black.png"


export default class SecondPage extends React.Component {
  
  render() {
    return <Layout title="Info">
            <SEO title="Info" />
      
    <div className="mx-auto col-xl-8 col-lg-10 col-md-12 col-sm-12 col-xs-12">
      <div className="row">
        <div className="mx-auto col-xl-2 col-lg-5 col-md-7 col-sm-10 col-xs-12">
          <h1 className=""> Info </h1>
        </div>
          <div className="mx-auto col-xl-6 col-lg-6 col-md-7 col-sm-8 col-xs-12">
        </div>
      </div>
      <div className ="row">
        <div className="mx-auto col-xl-2 col-lg-5 col-md-7 col-sm-10 col-xs-12">          
          <img src={Yksi} alt="profile" type="button" className="img-responsive profile-picture"></img>
        </div>
        <div className="mx-auto col-xl-6 col-lg-6 col-md-7 col-sm-10 col-xs-12">

          <p> AISTO luo kuvia tulevasta. Suunnitelmat heräävät eloon selkeillä ja uniikeilla 3D-visualisoinneilla, jotka pohjautuvat harmoniaan ja aitoon tunnelmaan. Realistiset materiaalit ja valo-olosuhteet viimeistelevät toiveiden mukaisen kokonaisuuden. Luonnollisuuteen, tasapainoon ja autenttisuuteen perustuvat kuvat saavat kohteesi erottumaan joukosta. </p>

          <p> 
          Visualisointien lisäksi tarjoan avustavaa arkkitehtisuunnittelua sekä graafisen suunnittelun palveluita.
          </p>

          <p>
          Tehdään ennennäkemättömästä todellisuutta.
          </p>

          <p> 
            <u>Juuso Lahtinen</u>  <br />
            p. +358 458925228 <br />
            juuso.lahtinen@aisto.fi  <br />
          </p>

          <div className="row my-4 pl-2">
            <a href="https://www.facebook.com/aisto.fi"> <img src={FbImg} className="img-responsive mx-2" alt="FB" style={{display: 'block'}} width="35em" height="35em"/> </a>
            <a href="https://instagram.com/aisto.fi"> <img src={IgImg} className="img-responsive mx-2" alt="IG" style={{display: 'block'}} width="35em" height="35em"/> </a>
          </div>
          <p>
            Y-tunnus: 3007015-7
          </p> 
          © AISTO {new Date().getFullYear()}
        </div>
      </div>
      </div>
    </Layout>
   }  
}